import { createRouter, createWebHistory } from "vue-router";
import { useStore } from 'vuex'; // Import the store
import Dashboard from "../views/Dashboard.vue";
import Signin from "../views/Signin.vue";
import InvoicesList from "../views/invoices/List.vue";
import InvoicesListLunas from "../views/invoices/ListLunas.vue";
// import Tables from "../views/Tables.vue";
// import Billing from "../views/Billing.vue";
// import RTL from "../views/Rtl.vue";
// import Profile from "../views/Profile.vue";
// import Signup from "../views/Signup.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/invoices",
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: InvoicesList,
  },
  {
    path: "/invoices-lunas",
    name: "InvoicesLunas",
    component: InvoicesListLunas,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   component: Tables,
  // },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   component: Billing,
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL",
  //   component: RTL,
  // },
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   component: Profile,
  // },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  // {
  //   path: "/signup",
  //   name: "Signup",
  //   component: Signup,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  const isLogin = store.state.isLogin;

  if (isLogin && to.name === 'Signin') {
    next({ name: 'Dashboard' });
  } else if (!isLogin && to.name !== 'Signin') {
    next({ name: 'Signin' }); 
  } else {
    next();
  }
});

export default router;
