<template>
    <div class="card">
      <div class="py-4 container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h3>Invoices</h3>
              <div>
                <button @click="loadInvoiceData" class="btn btn-warning mx-1">
                  Reload
                </button>
                <button @click="toggleForm" class="btn btn-primary">
                  {{ showForm ? 'Close' : 'Add Invoice' }}
                </button>
              </div>
            </div>
  
            <div v-if="showForm">
              <InvoiceForm @add-invoice="addInvoice" />
            </div>
  
            <div v-else>
              <div class="card-body px-0 pt-0 pb-2">
                <div v-if="loading" class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-else class="table-responsive p-0">
                  <table class="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th>No Invoice</th>
                        <th>Tanggal</th>
                        <th>Kepada</th>
                        <th>Kota</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="invoice in invoices" :key="invoice.invoiceNumber">
                        <td>{{ invoice.invoiceNumber }}</td>
                        <td>{{ invoice.date }}</td>
                        <td>{{ invoice.client }}</td>
                        <td>{{ invoice.city }}</td>
                        <td>{{ formatCurrency(invoice.total) }}</td>
                        <td>
                          <b :class="{
                            'text-success': invoice.status === 'Lunas',
                            'text-danger': invoice.status !== 'Lunas'
                          }">
                            {{ invoice.status ?? "Belum Lunas" }}
                          </b>
                        </td>
                        <td>
                          <button @click="showInvoice(invoice)" class="btn btn-info btn-sm mx-1">
                            <i class="ni ni-settings"></i> Show
                          </button>
                          <button @click="deleteInvoice(invoice)" class="btn btn-danger btn-sm">
                            <i class="ni ni-trash"></i> Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Invoice Details Modal -->
      <div class="py-4 container-fluid">
        <div class="row">
          <b-modal title="Invoice Details" size="lg" v-if="showModal" style="background-color:#F9F9F9; border-radius:2%">
            <div v-if="selectedInvoice">
              <h3 class="mt-4">Detail Invoice</h3>
              <p><strong>No Invoice:</strong> {{ selectedInvoice.invoiceNumber }}</p>
              <p><strong>Date:</strong> {{ selectedInvoice.date }}</p>
              <p><strong>Client:</strong> {{ selectedInvoice.client }}</p>
              <p><strong>City:</strong> {{ selectedInvoice.city }}</p>
              <p><strong>Total:</strong> <b> {{ formatCurrency(selectedInvoice.total) }}</b></p>
              <p><strong>Status: </strong> 
                <b :class="{
                  'text-success': selectedInvoice.status === 'Lunas',
                  'text-danger': selectedInvoice.status !== 'Lunas'
                }">
                  {{ selectedInvoice.status ?? "Belum Lunas" }}
                </b>
              </p>
              <button v-if="selectedInvoice.status!=='Lunas'" @click="setLunas" class="btn btn-primary btn-sm">
                <i class="ni ni-settings"></i> Lunas
              </button>
  
              <!-- Item Details -->
              <h4 class="mt-4">Item Details</h4>
              <div v-if="selectedInvoice.items.length">
                <table class="table table-striped table-custom" style="background-color:white; border: solid 1px black; border-radius:10px;">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in selectedInvoice.items" :key="item.description">
                      <td>{{ item.description }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ formatCurrency(item.unitPrice) }}</td>
                      <td>{{ formatCurrency(item.total) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <p>No items available.</p>
              </div>
  
              <div class="my-4">
                <label for="tempo">Tempo:</label>
                <input id="tempo" type="text" class="form-control" v-model="tempo" />
              </div>
              
              <button @click="printInvoice" class="btn btn-info btn-sm">
                <i class="ni ni-settings"></i> Print Invoice
              </button>
              
              <!-- Close Button -->
              <button @click="closeModal" class="btn btn-secondary btn-sm">
                <i class="ni ni-fat-remove"></i> Close
              </button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import InvoiceForm from '@/views/components/InvoiceForm.vue';
  import { generateInvoicePDF } from '@/views/js/PrintInvoiceTemplate.js';
  import gostoreService from '@/views/js/gostore.js';
  import Swal from 'sweetalert2';
  
  const showForm = ref(false);
  const showModal = ref(false);
  const loading = ref(false); // Loading state
  const invoices = ref([
    // Example invoices data
  ]);
  const tempo = ref('');
  
  onMounted(() => {
    init();
  });
  
  const init = () => {
    loadInvoiceData();
  };
  
  const loadInvoiceData = async () => {
    loading.value = true; // Set loading to true before starting fetch
    try {
      const response = await gostoreService.fetchData();
      const extractedInvoices = response.map(item => item.value);
      invoices.value = extractedInvoices.filter(ei => ei.status == "Lunas");
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      loading.value = false; // Set loading to false after fetch completes
    }
  };
  
  const selectedInvoice = ref(null);
  
  const toggleForm = () => {
    showForm.value = !showForm.value;
    if (showForm.value) {
      showModal.value = false; // Hide modal when adding an invoice
    }
  };
  
  const addInvoice = async (newInvoice) => {
    // add Column Status
    newInvoice["status"] = "Belum Lunas"
    const data = {
      key: `suun.invoice.${newInvoice.invoiceNumber}`,
      value: JSON.stringify(newInvoice)
    };
  
    try {
      const response = await gostoreService.postData(data);
      console.log(response)
      
      Swal.fire({
        title: 'Success!',
        text: 'Invoice submitted successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
  
      invoices.value.push(newInvoice);
      showForm.value = false;
      showModal.value = false; // Hide modal after adding an invoice
    } catch (error) {
      console.error('Error adding invoice:', error);
      
      Swal.fire({
        title: 'Error!',
        text: 'Failed to submit invoice.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };
  
  const deleteInvoice = async (invoiceToDelete) => {
    const key = `suun.invoice.${invoiceToDelete.invoiceNumber}`;
  
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete invoice ${invoiceToDelete.invoiceNumber}. This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      });
  
      if (result.isConfirmed) {
        const response = await gostoreService.deleteData(key);
  
        console.log(response);
  
        invoices.value = invoices.value.filter(invoice => invoice.invoiceNumber !== invoiceToDelete.invoiceNumber);
  
        Swal.fire(
          'Deleted!',
          `Invoice ${invoiceToDelete.invoiceNumber} has been deleted.`,
          'success'
        );
  
        showForm.value = false;
        showModal.value = false;
      }
    } catch (error) {
      console.error('Error deleting invoice:', error);
      Swal.fire('Error', 'There was an issue deleting the invoice.', 'error');
    }
  };
  
  const setLunas = async () => {
    Swal.showLoading();
    const invoice = selectedInvoice.value.invoiceNumber
    const key = "suun.invoice."+  invoice
  
    // search data + set to Lunas
    const searchData = await gostoreService.searchData(key);
    searchData.value.status = "Lunas"
  
    const data = {
      key: key,
      value: JSON.stringify(searchData.value)
    };
  
    try {
      const response = await gostoreService.putData(data);
      if(response){
        loadInvoiceData();
        selectedInvoice.value = searchData.value;
        Swal.close()
        Swal.fire({
          title: 'Success!',
          text: 'Invoice Lunas .',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      }
  
    } catch (error) {
      console.error('Error adding invoice:', error);
      Swal.close()
      Swal.fire({
        title: 'Error!',
        text: 'Failed to submit invoice.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  }
  
  const formatCurrency = (value) => {
    if (value){
      return `Rp.${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    } else {
      return value
    }
  };
  
  const showInvoice = (invoice) => {
    selectedInvoice.value = invoice;
    showModal.value = true;
  };
  
  const closeModal = () => {
    showModal.value = false;
  };
  
  const printInvoice = () => {
    if (selectedInvoice.value) {
      generateInvoicePDF(selectedInvoice.value, selectedInvoice.value.items, tempo.value);
    }
  };
  </script>
  
  <style scoped>
    /* Styles for loading spinner and table remain unchanged */
    .spinner-border {
      width: 3rem;
      height: 3rem;
      border-width: 0.3em;
    }
    .table {
      margin-top: 20px;
    }
    /* Border for the entire table */
    .table-custom {
      border: solid 1px black;
      border-radius: 10px;
      background-color: white;
      border-collapse: separate; /* Ensures the borders follow the border-radius */
      overflow: hidden; /* Ensures border-radius is applied to the table */
    }
  
    /* Border for the table header */
    .table-custom thead th {
      border-bottom: solid 2px black; /* Blue border for the header bottom */
      border-right: solid 1px black; /* Optional: Border between header cells */
    }
  
    /* Border for the last header cell to follow border-radius */
    .table-custom thead th:last-child {
      border-right: none; /* Remove right border on the last header cell */
    }
  
    /* Border for the table rows */
    .table-custom tbody td {
      border-bottom: solid 1px #DDDDDD; /* Light gray border for rows */
      border-right: solid 1px #DDDDDD; /* Optional: Border between row cells */
    }
  
    /* Remove right border on the last cell in each row */
    .table-custom tbody tr td:last-child {
      border-right: none;
    }
  
    /* Remove bottom border for the last row */
    .table-custom tbody tr:last-child td {
      border-bottom: none;
    }
  </style>
  
  